import React,{useEffect, useRef} from 'react'
import './careers.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaArrowRight } from "react-icons/fa6";
import career_top_img from "../../Assets/career_top_img.svg";
import career_work_withus from "../../Assets/career_work_withus.svg";
import { Link , useLocation } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Careers() {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({top:0,behavior: 'smooth',});
  }, [pathname]);

  const form = useRef();
  const formSubmit = (event) => {
    toast.dismiss()
    event.preventDefault();

    if (form.current[0].value && form.current[1].value && form.current[2].value && form.current[3].value && form.current[4].value && form.current[5].value && form.current[6].value) {
       emailjs
       .sendForm('service_2dtn13r', 'template_0mi5h2j', form.current, {
         publicKey: '8OyLJQL5vkIbmMVRw',
       })
       .then(
         () => {
           console.log('SUCCESS!');
           toast.success('SUCCESS')
           form.current.reset();
         },
         (error) => {
           console.log('FAILED...', error.text);
         },
       );
    }
    else {
      toast.error('Please fill all the details')
    }

    
  };

  return (
    <section className='careers_main'>

      <section className='common_top_Section Top_seciton'>
        <h1 className='main_title'>Career</h1>
        <div className='breadcrumbs'><Link to={'/'}><span>Home</span></Link> <FaArrowRight className='breadcrumbs_Arrow' /> <Link to={'/Careers'}><span>Career</span></Link></div>
        <div className='img_div'>
          <img src={career_top_img} alt="career top img" />
        </div>
      </section>

      <section className='careers_description'>
        <h2>Join Our Team</h2>

        <form ref= {form} className='careers_form'>
                  <div className='input_row'>
                      <div className='input_div'>
                          <label>First Name</label>
                          <input type="text" name="FirstName" id="FirstName" placeholder='First Name' />
                      </div>
                      <div className='input_div'>
                          <label>Last Name</label>
                          <input type="text" name="LastName" id="LastName" placeholder='Last Name' />
                      </div>
                  </div>
                  <div className='input_row'>
                      <div className='input_div'>
                          <label>Phone</label>
                          <input type="number" name="Phone" id="Phone" placeholder='Phone' />
                      </div>
                      <div className='input_div'>
                          <label>Email</label>
                          <input type="text" name="Email" id="Email" placeholder='Email' />
                      </div>
                  </div>
                  <div className='input_row'>
                      <div className='input_div'>
                          <label>Qualification</label>
                          <input type="text" name="Qualification" id="Qualification" placeholder='Qualification' />
                      </div>
                      <div className='input_div'>
                          <label>Years of Experience</label>
                          <input type="text" name="YearofPassing" id="YearofPassing" placeholder='Years of Experience' />
                      </div>
                  </div>
                  <div className='input_div'>
                          <label>Address</label>
                          <textarea name="Address" id="" cols="30" rows="10" placeholder='Address' ></textarea>
                    </div>
                    <button onClick={formSubmit}>Register Here</button>
        </form>
      </section>

      <section className='come_work_Withus'>
        <h4>COME WORK WITH US</h4>
        <h1>We Want You. Apply And Work With Us</h1>
        <Row className='come_work_Withus_row'>
        <Col xs={12} sm={12} md={12} lg={7} xxl={7} className='col_main col_main_2'>
            <h3>Our Culture</h3>
            <p>going above and beyond the call of duty to ensure the people paying for your goods and services are delighted</p>
            <ul>
              <li>
                <h6>Quality of work</h6>
                <p>It touches on every single aspect of the job and is a necessary skill in becoming a successful agent.</p>
              </li>
              <li>
                <h6>Trained and ready to give you back your time</h6>
                <p>A well trained agent can handle customer interactions more efficiently.</p>
              </li>
              <li>
                <h6>Premium Support</h6>
                <p>The number of calls per agent increases significantly when they are aware of the product and trained enough to offer superior customer service.</p>
              </li>
            </ul>

          </Col>
          <Col xs={12} sm={12} md={8} lg={5} xxl={5} className='col_main col_main_1'>
            <div className='img_div'>
              <img src={career_work_withus} alt="career work withus img" />
            </div>
          </Col>
        </Row>
      </section>

    </section>
  )
}

export default Careers