import React, { useEffect } from 'react'
import './service.css'
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaArrowRight } from "react-icons/fa6";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import service_top_img from "../../Assets/service_top_img.svg";
import our_service_call_center from "../../Assets/our_service_call_center.svg";
import our_service_debt_collection from "../../Assets/our_service_debt_collection.svg";
import our_service_inbound from "../../Assets/our_service_inbound.svg";
import our_service_24_support from "../../Assets/our_service_24_support.svg";
import our_service_virtual_assistance from "../../Assets/our_service_virtual_assistance.svg";
import our_service_live_chat_support from "../../Assets/our_service_live_chat_support.svg";
import learn_more_arrow from "../../Assets/learn_more_arrow.svg";
import our_process_discover from "../../Assets/our_process_discover.svg";
import our_process_strateize from "../../Assets/our_process_strateize.svg";
import our_process_implement from "../../Assets/our_process_implement.svg";
import service_testimonials from "../../Assets/service_testimonials.svg";
import service_sweet_review from "../../Assets/service_sweet_review.svg";
import testimonials_profile from "../../Assets/testimonials_profile.svg";
import { Link , useLocation } from "react-router-dom";

function Service() {


  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo({top:0,behavior: 'smooth',});
  }, [pathname]);

  return (
    <section className='service_main'>

         <section className='common_top_Section Top_seciton'>
        <h1 className='main_title'>Service</h1>
        <div className='breadcrumbs'><Link to={'/'}><span>Home</span></Link> <FaArrowRight className='breadcrumbs_Arrow' /> <Link to={'/Service'}><span>Services</span></Link></div>
        <div className='img_div'>
          <img src={service_top_img} alt="service top img" />
        </div>
      </section>

      <section className='our_service'>
        <h2>OUR SERVICES</h2>
        <h5>Customer Care, Crafted With Excellence</h5>

        <Row className='our_service_row'>
          <Col xs={12} sm={12} md={6} lg={4} xxl={4} className="col_main col_main1">
            <div className="card">
              <div className="img_div">
                <img src={our_service_call_center} alt="call center img" />
              </div>
              <h5>Call Center</h5>
              <p>We focus on customer satisfaction and offer comprehensive support.</p>
              <a href="">Learn More <img src={learn_more_arrow} alt="arrow" /></a>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xxl={4} className="col_main col_main2">
            <div className="card">
              <div className="img_div">
                <img src={our_service_debt_collection} alt="debt collection img" />
              </div>
              <h5>Debt Collection</h5>
              <p>customer who has fallen behind on payments and requires assistance to resolve the debt.</p>
              <a href="">Learn More <img src={learn_more_arrow} alt="arrow" /></a>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xxl={4} className="col_main col_main3">
            <div className="card">
              <div className="img_div">
                <img src={our_service_inbound} alt="inbound img" />
              </div>
              <h5>Inbound Requests</h5>
              <p>Customer service agents and support teams are responsible to monitor and attend the requests of the callers.</p>
              <a href="">Learn More <img src={learn_more_arrow} alt="arrow" /></a>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xxl={4} className="col_main col_main4">
            <div className="card">
              <div className="img_div">
                <img src={our_service_24_support} alt="24/7 support img" />
              </div>
              <h5>Support</h5>
              <p>Customers are extremely happy when they receive aid when they demand it. This is how customers react to customer service.</p>
              <a href="">Learn More <img src={learn_more_arrow} alt="arrow" /></a>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xxl={4} className="col_main col_main5">
            <div className="card">
              <div className="img_div">
                <img src={our_service_virtual_assistance} alt="virtual assistance img" />
              </div>
              <h5>Virtual Assistance</h5>
              <p>virtual assistant can answer calls from customers or clients and help them resolve their issues and anything else that needs attention.</p>
              <a href="">Learn More <img src={learn_more_arrow} alt="arrow" /></a>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xxl={4} className="col_main col_main6">
            <div className="card">
              <div className="img_div">
                <img src={our_service_live_chat_support} alt="live chat img" />
              </div>
              <h5>Call Support</h5>
              <p>call enables lower wait times because an agent can talk to multiple people right away via call. By contrast, reps can speak to only one customer at a time by phone, and email creates delays.</p>
              <a href="">Learn More <img src={learn_more_arrow} alt="arrow" /></a>
            </div>
          </Col>
        </Row>
      </section>

      <section className='service_our_process'>
        <h2>OUR PROCESS</h2>
        <h6>We Anticipate, We Serve, We Exceed</h6>

        <Row className='service_our_process_row'>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main1' >
            <div className='card'>
              <div className='img_div'>
                <img src={our_process_discover} alt="our process discover" />
                <span>1</span>
              </div>
              <h6>Discover</h6>
              <p>We seek information from clients that will help refine the challenge, journey map, and diagnosis of barriers.</p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main2' >
          <div className='card'>
            <div className='img_div'>
              <img src={our_process_strateize} alt="our process strateize" />
              <span>2</span>
            </div>
              <h6>Strategize</h6>
              <p>the primary objective is to generate new sales and revenue while growing the customer base.</p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main3' >
          <div className='card'>
            <div className='img_div'>
              <img src={our_process_implement} alt="our process implement" />
              <span>3</span>
            </div>
              <h6>Implement</h6>
              <p>Agent is able to give the customer all the information they require during the call without passing it to someone else.</p>
            </div>
          </Col>
        </Row>
      </section>

      <section className='service_testimonials'>
        <Row className='service_testimonials_row'>
          <Col xs={12} sm={12} md={5} lg={5} xxl={5} className='col_main col_main_1'>
            <div className='img_div'>
              <img src={service_sweet_review} alt="service testimonials img" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={7} lg={7} xxl={7} className='col_main col_main_2'>
           <div className='testimonials_top_content'>
           <h3>TESTIMONIAL</h3>
            <h5>Sweet Reviews From Our Clients</h5>
            <p>In a digital world, the human touch still matters. Tele sales service is the bridge that connects businesses with their customers, creating genuine connections and driving success.</p>
           </div>

           <div className='testimonials_bottom_content'>
            <p>Had a great experience with them</p>
            <div className='img_div_main'>
              <div className='img_div'>
                <img src={testimonials_profile} alt="testimonials profile img" />
              </div>
              <div className='content'>
              <h6>Rahul Sharma</h6>
              <p>28 July 2020</p>
              </div>
            </div>
           </div>

          </Col>
        </Row>
      </section>


    </section>
  )
}

export default Service