import React,{useEffect, useRef} from 'react';
import './register.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaArrowRight } from "react-icons/fa6";
import register_top_img from "../../Assets/register_top_img.svg";
import our_value_passion from "../../Assets/our_value_passion.svg";
import our_value_community from "../../Assets/our_value_community.svg";
import our_value_commitment from "../../Assets/our_value_commitment.svg";
import our_value_growth from "../../Assets/our_value_growth.svg";
import our_value_honesty from "../../Assets/our_value_honesty.svg";
import our_value_teamwork from "../../Assets/our_value_teamwork.svg";
import { Link , useLocation } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Register() {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({top:0,behavior: 'smooth',});
  }, [pathname]);

  const form = useRef();
  const formSubmit = (event) => {
    toast.dismiss()
    event.preventDefault()

    if (form.current[0].value && form.current[1].value && form.current[2].value && form.current[3].value && form.current[4].value && form.current[5].value && form.current[6].value && form.current[7].value) {
      emailjs.sendForm('service_2dtn13r', 'template_gdaasyi', form.current, {
        publicKey: '8OyLJQL5vkIbmMVRw',
      }).then(
        () => {
          console.log('SUCCESS!');
         //  alert('Client Register SUCCESS!!!')
          toast.success('SUCCESS')
       form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
   }
   else {

     toast.error('Please fill all the details')
   }
  };



  return (
    <section className='register_main'>

        <section className='common_top_Section Top_seciton'>
        <h1 className='main_title'>Contact Us</h1>
        <div className='breadcrumbs'><Link to={'/'}><span>Home</span></Link> <FaArrowRight className='breadcrumbs_Arrow' /> <Link to={'/ContactUs'}><span>Contact us</span></Link> <FaArrowRight className='breadcrumbs_Arrow' /> <Link to={'/ClientRegister'}><span>Client Register</span></Link></div>
        <div className='img_div'>
          <img src={register_top_img} alt="register top img" />
        </div>
      </section>

      <section className='register_description'>
        <h2>client Registration</h2>
        <h5>Please Enter the Required Detauls to continue with our services</h5>

        <form ref= {form} className='register_form'>
                  <div className='input_row'>
                      <div className='input_div'>
                          <label>First Name</label>
                          <input type="text" name="FirstName" id="FirstName" placeholder='First Name' />
                      </div>
                      <div className='input_div'>
                          <label>Last Name</label>
                          <input type="text" name="LastName" id="LastName" placeholder='Last Name' />
                      </div>
                  </div>
                  <div className='input_row'>
                      <div className='input_div'>
                          <label>Phone</label>
                          <input type="number" name="Phone" id="Phone" placeholder='Phone' />
                      </div>
                      <div className='input_div'>
                          <label>Email</label>
                          <input type="text" name="Email" id="Email" placeholder='Email' />
                      </div>
                  </div>
                  <div className='input_row'>
                      <div className='input_div'>
                          <label>Bussiness Name</label>
                          <input type="text" name="Bussiness" id="Bussiness" placeholder='Bussiness' />
                      </div>
                      <div className='input_div'>
                          <label>Business Type</label>
                          <input type="text" name="BusinessType" id="BusinessType" placeholder='Business Type ( Eg: IT or NON IT )' />
                      </div>
                  </div>
                  <div className='input_div'>
                          <label>Address</label>
                          <textarea name="Address" id="" cols="30" rows="10" placeholder='Address' ></textarea>
                    </div>
                  <div className='input_div'>
                          <label>URL</label>
                          <input type="text" name="url" id="url" placeholder='URL' />
                    </div>
                    <button className='register_page_submit' onClick={formSubmit}>Register Here</button>
        </form>
      </section>

      <section className='register_our_values'>
        <h2>Our Skills</h2>
        <h5>A company's progress happens when skillful, bold leaders capture the chance to change things for the better.</h5>

        <Row className='register_our_values_row'>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main1' >
            <div className='img_div'>
              <img src={our_value_passion} alt="our value passion img" />
            </div>
            <h5>Passion</h5>
            <p>We creating positive experiences and exceeding expectations, which leads to customer loyalty.</p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main2' >
            <div className='img_div'>
              <img src={our_value_community} alt="our value community img" />
            </div>
            <h5>Community</h5>
            <p>Its a remote solutions outsourcing provider with a network of agents ready to work for you.</p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main3' >
            <div className='img_div'>
              <img src={our_value_commitment} alt="our value commitment img" />
            </div>
            <h5>Commitment</h5>
            <p>a retention strategy that focuses on keeping people loyal by consistently delivering on the brand's value proposition and fostering relationships.</p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main4' >
            <div className='img_div'>
              <img src={our_value_growth} alt="our value growth img" />
            </div>
            <h5>Growth</h5>
            <p>We identify potential customers, gather relevant information, and nurture those leads through personalized interactions.</p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main5' >
            <div className='img_div'>
              <img src={our_value_honesty} alt="our value honesty img" />
            </div>
            <h5>Honesty</h5>
            <p>we are open and honest in all our dealings with customers, providing them with accurate information and addressing any concerns or issues promptly</p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main6' >
            <div className='img_div'>
              <img src={our_value_teamwork} alt="our value teamwork img" />
            </div>
            <h5>Team Work</h5>
            <p>Having good teamwork is fundamental because each agent is considered a part of something bigger </p>
          </Col>
        </Row>
      </section>

    </section>
  )
}

export default Register