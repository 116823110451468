import React, { useEffect } from 'react'
import './aboutUs.css'
import Container from 'react-bootstrap/Container';
import { Link , useLocation } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaArrowRight } from "react-icons/fa6";
import about_smart_solution from "../../Assets/about_smart_solution.svg";
import about_smart_solution_col2 from "../../Assets/about_smart_solution_col2.svg";
import our_value_passion from "../../Assets/our_value_passion.svg";
import our_value_community from "../../Assets/our_value_community.svg";
import our_value_commitment from "../../Assets/our_value_commitment.svg";
import our_value_growth from "../../Assets/our_value_growth.svg";
import our_value_honesty from "../../Assets/our_value_honesty.svg";
import our_value_teamwork from "../../Assets/our_value_teamwork.svg";
import get_started from "../../Assets/get_started1.svg";
import our_team_third_img from "../../Assets/our_team_third_img.svg";
import our_team_first_img from "../../Assets/our_team_first_img.svg";
import our_team_second_img from "../../Assets/our_team_second_img.svg";
import about_us_top_img from "../../Assets/about_us_top_img.svg";
import our_process_discover from "../../Assets/our_process_discover.svg";
import our_process_strateize from "../../Assets/our_process_strateize.svg";
import our_process_implement from "../../Assets/our_process_implement.svg";

const About = () => {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({top:0,behavior: 'smooth',});
  }, [pathname]);

  return (
    <section className='about_us'>

      <section className='common_top_Section Top_seciton'>
        <h1 className='main_title'>About Us</h1>
        <div className='breadcrumbs'><Link to={'/'}><span>Home</span></Link> <FaArrowRight className='breadcrumbs_Arrow' /> <Link to={'/AboutUs'}><span>About</span></Link></div>
        <div className='img_div'>
          <img src={about_us_top_img} alt="about us top img" />
        </div>
      </section>

      <section className='about_smart_solution'>
        <h6>SMART SOLUTIONS</h6>
        <h2>Shining a Light on Exceptional Call</h2> 
        <h2>Center Experiences</h2>

        <Row className='about_smart_solution_row'>
          <Col xs={12} sm={12} md={7} lg={7} xxl={7} className='col_main_1'>
            <div className='img_div'>
              <img src={about_smart_solution} alt="about smart solution img" />
              <div className='our_vission'>
                <h6>Our Vision</h6>
                <p>Our vision is to provide premier business process outsourcing service to our clients by combining cutting edge technology, talented, dedicated professionals and a commitment to quality.</p>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} xxl={5} className='col_main_2'>
            <div className='right_side_img'>
              <div className='img_div'>
            <img src={about_smart_solution_col2} alt="" />
              </div>
            <div className='our_mimssion'>
              <h6>Our Mission</h6>
              <p>Our Mission is to provide top-notch BPO service to clients at an affordable price. We aim to simplify the outsourcing process, facilitate seamless communication, and drive continuous improvement in voice based services.</p>
            </div>
            </div>
          </Col>
        </Row>

      </section>

      <section className='about_our_values'>
        <h2>OUR VALUES</h2>
        <h5>Striving for Perfection in Customer Care</h5>

        <Row className='about_our_values_row'>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main1' >
            <div className='img_div'>
              <img src={our_value_passion} alt="our value passion img" />
            </div>
            <h5>Echoes of Empathy</h5>
            <p>We listen with empathy, understanding and compassion amplifying the voice of both our clients and their customers to cultivate genuine connections and meaningful experiences. </p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main2' >
            <div className='img_div'>
              <img src={our_value_community} alt="our value community img" />
            </div>
            <h5>Customer Centricity</h5>
            <p>We prioritize our client’s needs above all else, striving to exceed their expectations through proactive communication, personalized solutions, and unwavering dedication.
</p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main3' >
            <div className='img_div'>
              <img src={our_value_commitment} alt="our value commitment img" />
            </div>
            <h5>Pulse of Precision</h5>
            <p>With every word spoken, we conduct ourselves with meticulous precision, ensuring accuracy, clarity and impact in every interaction.</p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main4' >
            <div className='img_div'>
              <img src={our_value_growth} alt="our value growth img" />
            </div>
            <h5>Excellence</h5>
            <p>We purse excellence in everything we do, delivering high quality service with precision, efficiency and a commitment to continuous improvement.
</p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main5' >
            <div className='img_div'>
              <img src={our_value_honesty} alt="our value honesty img" />
            </div>
            <h5>Integrity</h5>
            <p>Our commitment to unwavering integrity reverberates through every conversation, fostering trust, loyalty and enduring partnership. </p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main6' >
            <div className='img_div'>
              <img src={our_value_teamwork} alt="our value teamwork img" />
            </div>
            <h5>Sonic Synergy</h5>
            <p>Harnessing the power of voice, we create seamless harmony between clients and their customers, orchestrating conversations that resonate and inspire action.</p>
          </Col>
        </Row>
      </section>

      <section className='about_get_started'>
        <img src={get_started} alt="get started img" />
        <div className='get_started_content'>
          <h4>GET STARTED</h4>
          <h2>Customer Care, Crafted with Excellence</h2>
          <p>It's about providing a seamless, personalized experience, where a customer feels heard, personally taken care of, and that their expectations have been surpassed.</p>
          <button>Gets Started</button>
        </div>
      </section>

      <section className='about_our_process'>
        <h2>OUR PROCESS</h2>
        <h6>We Anticipate, We Serve, We Exceed</h6>

        <Row className='about_our_process_row'>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main1' >
            <div className='card'>
              <div className='img_div'>
                <img src={our_process_discover} alt="our process discover" />
                <span>1</span>
              </div>
              <h6>Discover</h6>
              <p>We seek information from clients that will help refine the challenge, journey map, and diagnosis of barriers.</p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main2' >
          <div className='card'>
            <div className='img_div'>
              <img src={our_process_strateize} alt="our process strateize" />
              <span>2</span>
            </div>
              <h6>Strategize</h6>
              <p>the primary objective is to generate new sales and revenue while growing the customer base.</p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main3' >
          <div className='card'>
            <div className='img_div'>
              <img src={our_process_implement} alt="our process implement" />
              <span>3</span>
            </div>
              <h6>Implement</h6>
              <p> Agent is able to give the customer all the information they require during the call without passing it to someone else.</p>
            </div>
          </Col>
        </Row>
      </section>

      <section className='about_our_team'>
        <h2>OUR TEAM</h2>
        <h5>Meet Our Professional Team Members</h5>

        <Row className='about_our_team_row'>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main1'>
            <div className='img_div'>
              <img src={our_team_first_img} alt="our_team_first_img" />
            </div>
            <p>Hailee Keinz</p>
            <h5>Call Support</h5>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main2'>
            <div className='img_div'>
              <img src={our_team_second_img} alt="our_team_second_img" />
            </div>
            <p>Sherya</p>
            <h5>Call Support</h5>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} xxl={4} className='col_main col_main3'>
            <div className='img_div'>
              <img src={our_team_third_img} alt="our_team_third_img" />
            </div>
            <p>Hallen killer</p>
            <h5>Call Support</h5>
          </Col>
        </Row>
      </section>

    </section>
  )
}

export default About