import React, { useEffect } from 'react'
import "./footer.css"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoLocationOutline } from "react-icons/io5";
import footer_logo from "../../Assets/footer_logo.svg";
import footer_contact_icon from "../../Assets/footer_contact_icon.svg";
import footer_email_icon from "../../Assets/footer_email_icon.svg";
import footer_location_icon from "../../Assets/footer_location_icon.svg";
import { Link , useLocation } from "react-router-dom";

function Footer() {

  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo({top:0,behavior: 'smooth',});
  }, [pathname]);

  return (
    <section className='footer'>
        {/* <Container fluid> */}

        <Row className='footer_content_top'>
          <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} >
            <Link to={"/"}>
            <div className='img_div'>
              <img src={footer_logo} alt="footer logo img" />
            </div>
            </Link>
          </Col>
          <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9} >
            <p className='company_description'>We’re here to make your experience as seamless as possible </p>
          </Col>
        </Row>

      <Row className='footer_row'>
        <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} >
          <div className='usefull_links'>
          <h5>Useful Links</h5>
            <ul>
                <li><span><Link to="/AboutUs">About Company</Link></span></li>
                <li><span><Link to="/Careers">Career</Link></span></li>
                <li><span><Link to="/Service">Services</Link></span></li>
                <li><span><Link to="/ContactUs">Contact</Link></span></li>
            </ul>
          </div>
        </Col>
        <Col xs={12} sm={12} md={5} lg={5} xl={5} xxl={5} >
          <div className='address_links'>
            <div className='img_div'>
              <img src={footer_location_icon} alt="footer location icon img" />
            </div>
            <h5>Address</h5>
            <p >33, Hakim Ajmal Khan Rd, Chinna Chokikulam, Madurai, Tamil Nadu 625002</p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} >
            <ul className='contact_links'>
              <li>
            <h5>Contact</h5>
              </li>
              <li>
                <div className='img_div'>
                  <img src={footer_contact_icon} alt="footer contact icon img" />
                </div>
                <p>+91 9980592929</p>
                <p>+91 9844561185</p>
              </li>
              <li className='email_footer'>
              <div className='img_div'>
                  <img src={footer_email_icon} alt="footer email icon img" />
                </div>
                <p>sales@Kippocampus.com</p>
                <p>service@Kippocampus.com</p>
              </li>
            </ul>
        </Col>
      </Row>
    {/* </Container> */}
    </section>
  )
}

export default Footer