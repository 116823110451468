import React,{useState,useEffect} from 'react'
import './home.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import why_choose_us_img from "../../Assets/why_choose_us_img2.svg";
import contact_center_service from "../../Assets/contact_center_service.svg";
import smart_solutoin_img from "../../Assets/smart_solutoin_img.png";
import top_section_card_img from "../../Assets/top_section_card_img.svg";
import our_mission2 from "../../Assets/our_mission2.png";
import our_vision2 from "../../Assets/our_vision2.png";
import video from "../../Assets/video2.mp4";
import { RiFacebookFill } from "react-icons/ri";
import { ImTwitter } from "react-icons/im";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { Link , useLocation } from "react-router-dom";

function Home() {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({top:0,behavior: 'smooth',});
  }, [pathname]);

  const [play, setplay] = useState(true)
  var myVideo = document.getElementById("singleVideo");

  useEffect(() => {
    myVideo?.addEventListener('ended', function() {
      setplay(true)
    });
    myVideo?.addEventListener('pause', function() {
      setplay(true)
    });
    myVideo?.addEventListener('play', function() {
      setplay(false)
    });
  },)  

  const popupClose = () => {

    var myVideo = document.getElementById("singleVideo");

    if(play){
      myVideo.play();
    }
    else if(!play){
      myVideo.pause();
    }
    setplay(!play)
  }

  return (
    <Container fluid>

      <section className='smart_Solution'>
        <Row className='smart_Solution_row'>
          <Col xs={12} sm={12} md={12} lg={6} xxl={6} className='col_main col_main_1'>
            <h6>SMART SOLUTIONS</h6>
            <h2>Empowering connection, Elevating Experience & Your Voice process Partner of Choice</h2>
            <p>Improve customer experience by listening and acting on their feedback</p>
            <Link to="/ContactUs"><button><IoCall /> Call Free</button></Link>
          </Col>
          <Col xs={12} sm={12} md={1} lg={6} xxl={6} className='col_main col_main_2'>
            <img src={smart_solutoin_img} alt="smart solutoin img" />
          </Col>
        </Row>

        <Row className='smart_Solution_row2'>
        <Col xs={12} sm={12} md={12} lg={12} xxl={12} className='top_card_main'>
            <Row className='top_center_card_row'>
              <Col xs={12} sm={6} md={4} lg={4} xxl={4}>
                <div className='card card1'>
                {/* <img src={top_section_card_img} alt="" /> */}
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} xxl={4} >
                <div className='card card2'>
                <h5><span>Our</span> <img src={our_vision2} className='our_vision_img' alt="" /> <span className='our_vision_span'>Vision</span></h5>
                <p>Our vision is to provide premier business process outsourcing service to our clients by combining cutting edge technology, talented, dedicated professionals and a commitment to quality.</p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} xxl={4}>
                <div className='card card3'>
                {/* <h5>Our Mission</h5> */}
                <h5><span>Our</span> <img src={our_mission2} className='our_mission_img' alt="" /> <span className='our_mission_span' >Mission</span></h5>
                <p>Our Mission is to provide top-notch BPO service to clients at an affordable price. We aim to simplify the outsourcing process, facilitate seamless communication, and drive continuous improvement in voice based services. </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className='What_we_do'>
        <h2>WHAT WE DO</h2>
        <h2>Optimizing Your Customer Support Experience</h2>
        <Row className='What_we_do_row'>
          <Col xs={12} sm={12} md={6} lg={6} xxl={7} className='col_main col_main_1'>
            <p>At Kippocampus, We Specialise in providing comprehensive solutions to meet the diverse needs of business in the BPO voice process. Leveraging cutting edge technology and team of skilled professionals, we offer a range of services including Customer support, sales, telemarketing and more.</p>
            <ul>
              <li>Language Combinations</li>
              <li>Outsourced Data Analytics</li>
              <li>Contact Center Outsourcing</li>
            </ul>
            {/* <button className='Know_more'>Know more</button> */}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xxl={5} className='col_main col_main_2'>
          <button type="button" onClick={()=>{
              popupClose()
            }} >
                    { play ? <FaPlay />: <FaPause />}
                    {/* <FaPlay /> */}
                </button>
            <div className='video_div'>
              <vg-player>
                      <video id="singleVideo" width="100%" height="100%" controls disablepictureinpicture="false" controlslist="nodownload noplaybackrate" >
                          <source src={video} type="video/mp4"/>
                      </video>
                  </vg-player>
            </div>
          </Col>
        </Row>
      </section>

      <section className='why_choose_us'>
        <Row className='why_choose_us_row'>
          <Col xs={12} sm={12} md={8} lg={5} xxl={5} className='col_main col_main_1'>
            <div className='img_div'>
              <img src={why_choose_us_img} alt="why choose us img" />
              <span className='experience_div'>
                <span>20</span>
                <span>Years Of Experience</span>
              </span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={7} xxl={7} className='col_main col_main_2'>
            <h3>WHY CHOOSE US</h3>
            <h5>We work with you to find the perfect for your needs.</h5>
            <p>It includes communication skills, product or service training, call handling techniques, customer service best practices, and company policies and procedures.</p>
            <ul>
              <li>
                <h6>Tailored Solutions</h6>
                <p> We begin by understanding your unique business requirements and objectives. Our team works closely with you to customize voice process solutions that align with your specific needs & goals</p>
              </li>
              <li>
                <h6>Quality Assurance</h6>
                <p>We maintain stringent quality assurance measure to ensure consistently high standards of service delivery. Our team monitors interactions in real time, conducts regular performance evaluations and implements feedback mechanisms to continuously improve service quality.</p>
              </li>
              <li>
                <h6>Data Security & Compliance</h6>
                <p>We prioritise the security & confidential of your sensitive information. Our systems are equipped with robust security protocols to safeguard data and ensure compliance with industry regulation such as GDPR and PCI-DSS.</p>
              </li>
            </ul>

          </Col>
        </Row>
      </section>

      <scetion className='contact_technology'>
        <div className='contact_technology_main'>
          <img src={contact_center_service} alt="contact center service img" />
          <div className='sub_box_div'>
            <h6>Learn About Our Solutions, Contact Center & Technology</h6>
            <p>It is supported by many technologies designed to enhance the customer experience, improve the operating and management efficiency, or lower the overall costs of running a contact center.</p>
            <button><Link to={'/Service'}>See Services</Link></button>
          </div>
        </div>
      </scetion>

      <section className='follow_us'>
        <h2>FOLLOW US</h2>
        <ul>
          <li><a href=""><RiFacebookFill /></a></li>
          <li><a href=""><ImTwitter /></a></li>
          <li><a href=""><AiFillInstagram /></a></li>
          <li><a href=""><IoLogoWhatsapp /></a></li>
        </ul>
      </section>
    </Container>
  )
}

export default Home